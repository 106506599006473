import { makeToast } from '@/modules/budget-request/components/js/some-functions';

export interface IBatchUpParams {
    cur_year: number;
    region: number;
    variant: string;
    abp: number;
    lang: string;
    prg: number | null;
    gu?: string;
}

export async function startBatchUpload(params: IBatchUpParams, that: any) {
    if (!params.gu) { delete params.gu; }
    try {
        const url = '/api/summary/report/create';
        let response: any = await fetch(url, {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify(params)
        });
        response = await response.json();
        makeToast(that, 'success', 'Сообщение', 'Загрузка запущена');
    } catch (error) {
        makeToast(that, 'danger', 'Ошибка загрузки', (error as Error).toString());
    } 
}