
















/*interface ISignUser {
    create_date: null | number,
    name: string,
    userName: string, 
    user_id: string
}*/

import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
    name: 'c-sign-modal'
})
export default class CBudgetUserSignModal extends Vue {
    private showModal = false;
    private signUserLst: any[] = [];

    private showEvent(signUserLst: any[]) {
        this.signUserLst = signUserLst;
        this.showModal = true;
    }
}
