




















import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
    name: 'c-start-sign-modal'
})
export default class CBudgetUserStartSignModal extends Vue {
    private showModal = false;

    private showEvent() {
        this.showModal = true;
    }

    private recalClk() {
        this.showModal = false;
        this.$emit('startSign');
    }
}
