








// ------------------------------компонент через который открываются свод ГККП c учётом платных и без учёта платных
import {Component, Vue, Prop} from 'vue-property-decorator';
import CBubgGkkpSumCalcTbl from '@/modules/budget-request/components/budget-sum-calc/budget-gkkp-sum-calc-table.vue';
import CBubgGkkpPaidTab from '@/modules/budget-request/components/budget-sum-calc/budget-gkkp-paid-sum-calc-table.vue';

@Component({
    components: {
        'c-calc-gkkp-tab': CBubgGkkpSumCalcTbl,
        'c-calc-gkkp-paid-tab': CBubgGkkpPaidTab
    },
    name: 'c-budg-sum-calc-ggkp-main'
})
export default class CBudgSumCalcGkkpMain extends Vue {
    @Prop({
        required: true,
        default: 0
    })
    private curYearProp!: number;

    @Prop({
        required: true,
        default: null
    })
    private curAbpProp!: any | null;

    @Prop({
        required: true,
        default: null
    })
    private curDataTypeProp!: any | null;

    @Prop({
        required: true,
        default: null
    })
    private regionProp!: any | null;

    @Prop({
        required: true,
        default: null
    })
    private curVariantProp!: any | null;

    @Prop({
        required: true,
        default: null
    })
    private curGkkpProp!: any | null;

    @Prop({
        required: true,
        default: null
    })
    private variantEndDateProp!: any | null;

    @Prop({
        required: true,
        default: null
    })
    private gkkpTypeProp!: any | null;

    private curGkkp: any | null = null;
    private curYear: number = 0;
    private curAbp: any = null;
    private curDataType: any = null;
    private curRegion: any = null;
    private curVariant: any = null;
    private variantEndDate: any | null = null;
    private gkkpType: any | null = null;

    private chgEvent(params: any) {
        this.curAbp = params.curAbp;
        this.curYear = params.curYear;
        this.curDataType = params.curDataType;
        this.curRegion = params.regionProp;
        this.curVariant = params.curVariant;
        this.curGkkp = params.curGkkp;
        this.variantEndDate = params.variantEndDate;
        this.gkkpType = params.gkkpType;
        this.chgProps();
    }

    private mounted() {
        this.curAbp = this.curAbpProp;
        this.curYear = this.curYearProp;
        this.curDataType = this.curDataTypeProp;
        this.curRegion = this.regionProp;
        this.curVariant = this.curVariantProp;
        this.curGkkp = this.curGkkpProp;
        this.variantEndDate = this.variantEndDateProp;
        this.gkkpType = this.gkkpTypeProp;
        this.chgProps();
    }

    private async chgProps() {
        const params = { curYear: this.curYear, curAbp: this.curAbp, curDataType: this.curDataType, regionProp: this.curRegion, curVariant: this.curVariant, curGkkp: this.curGkkp, variantEndDate: this.variantEndDate };
        // await this.$nextTick();
        if (this.gkkpType == 0) {
            if (this.$refs.refGkkp0) {
                // eslint-disable-next-line
                // @ts-ignore
                this.$refs.refGkkp0.chgEvent(params);
            }
        } else {
            if (this.$refs.refGkkp1) {
                // eslint-disable-next-line
                // @ts-ignore
                this.$refs.refGkkp1.chgEvent(params);
            }
        }
    }
}
