interface IResultCalc {
    val: number;
    formBipMap: Map<string, IFormBip[]> | null
}

export interface IFormBip {
    form: string | null;
    bipCode: string | null;
    value: number;
    field: string;
    valueSourceLink?: any;
    bipName?: string | null;
}



export function calcProgn(obj: any, key: number): number | null {
    if (obj.size === 0) { return null; }
    const result = calcPrognFunc(obj, key, null, null);
    if (typeof result == 'number') { return result; }
    return null;
}

export function calcPrognWithForm(obj: any, key: number, formMap: Map<string, IFormBip[]>, field: string): IResultCalc {
    if (obj.size === 0) { return { val: 0, formBipMap: formMap }; }
    const result = calcPrognFunc(obj, key, formMap, field);
    if (typeof result != 'number') { return result; }
    return { val: 0, formBipMap: formMap };
}

// формулы расчёта прогноза по спецификациям
function calcPrognFunc(obj: any, key: number, formMap: Map<string, IFormBip[]> | null, field: string | null): IResultCalc | number  {
    let result: IResultCalc;
    if (formMap) {
        result = { val: 0, formBipMap: formMap };
    } else {
        result = { val: 0, formBipMap: null };
    }
    /*if (obj.get(key.toString())) { calc(result, obj.get(key.toString()), field); }
    switch (key) {
        case 159:
            for (let i = 1; i < 4; i++) {
                if (obj.get(`0${i}-159`) !== undefined) { calc(result, obj.get(`0${i}-159`), field); }
            }
            if (obj.get('02-159_1') !== undefined) { calc(result, obj.get('02-159_1'), field); }
            break;
        case 339:
            if (obj.get('02-159_2') !== undefined) { calc(result, obj.get('02-159_2'), field); }
            if (obj.get('01-339') !== undefined) { calc(result, obj.get('01-339'), field); }
            if (obj.get('02-339') !== undefined) { calc(result, obj.get('02-339'), field); }
            break;
        default:
            for (let i = 1; i < 10; i++) {
                if (obj.get(`0${i}-${key}`) !== undefined) { calc(result, obj.get(`0${i}-${key}`), field); }
            }
            for (let i = 10; i < 100; i++) {
                if (obj.get(`${i}-${key}`) !== undefined) { calc(result, obj.get(`${i}-${key}`), field); }
            }
    }
    if (obj.get(null)) { calc(result, obj.get(null), field); }*/

    for (const [k, val] of obj) {
        calc(result, val, field);
    }
    result.val = Math.round(result.val * 100) / 100;
    if (formMap) {
        return result;
    } else {
        return result.val;
    }
}

function calc(result: IResultCalc, obj: any, field: string | null) {
    if (obj && Array.isArray(obj)) {
        for (const el of obj) {
            calcObj(result, el, field);
        }
    } else {
        calcObj(result, obj, field);
    }
}

function calcObj(result: IResultCalc, obj: any, field: string | null) {
    result.val += Math.round(obj.value);
    if (result.formBipMap && field!==null)  {
        let formBip = obj.form;
        if (formBip === null) { formBip = obj.bipCode; }
        let arr = result.formBipMap.get(formBip);
        if (!arr) { arr = []; }
        arr.push({ form: obj.form, bipCode: obj.bipCode, value: obj.value, field: field, valueSourceLink: obj.valueSourceLink, bipName: obj.bipName });
        result.formBipMap.set(formBip, arr);
    }
}