import { Component, Vue } from "vue-property-decorator";
type extraType = null | number | object;

@Component
export default class BpI18nHandlerMixin extends Vue {
    private getText(link: string, field: string, extra: extraType = null): string {
        const mainTextFieldName = link + field;
        if (extra) {
            if (typeof extra === 'number') return this.$tc(mainTextFieldName, extra)
            else return this.$t(mainTextFieldName, extra) as string;
        }
        return this.$t(mainTextFieldName) as string;
    }
    private commonLink = 'modules.budget_request.calculation_forms.table_headers.';
    private reportsLink = 'modules.budget.budget_request';
    private budgetRequest = 'modules.budget_request';

    public getFilterText(field: string, extra: extraType = null): string {
        const link = `${this.budgetRequest}.filter.`;
        return this.getText(link, field, extra);
    }

    public getDuplDataText(field: string, extra: extraType = null): string {
        const link = `${this.budgetRequest}.duplicate_data.`;
        return this.getText(link, field, extra);
    }

    public getRepText(field: string, extra: extraType = null): string {
        const link = `${this.reportsLink}.`;
        return this.getText(link, field, extra);
    }

    public getUnitsText(field: string, extra: extraType = null): string {
        const link = `${this.commonLink}units.`;
        return this.getText(link, field, extra);
    }

    public getCommonText(field: string, extra: extraType = null): string {
        const link = `${this.commonLink}common.`;
        return this.getText(link, field, extra);
    }

    public getErrText(field: string, extra: extraType = null): string {
        const link = `${this.commonLink}errors.`;
        return this.getText(link, field, extra);
    }

    public getDecText(field: string, extra: extraType = null): string {
        const link = `${this.commonLink}decodes.`;
        return this.getText(link, field, extra);
    }

    public setFormText(form: string) {
        return (field: string, extra: extraType = null) => {
            const link = `${this.commonLink}${form}`;
            return this.getText(link, field, extra);
        }
    }
}