
























import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
    name: 'c-sign-recal-modal'
})
export default class CBudgetUserSignRecalModal extends Vue {
    private showModal = false;
    private commentTxt = '';

    private showEvent() {
        this.showModal = true;
    }

    private recalClk() {
        this.$emit('recalClk', this.commentTxt.trim());
        this.commentTxt = '';
        this.showModal = false;
    }
}
