












































import { Component, Vue, Prop } from 'vue-property-decorator';
import { makeToast } from '@/modules/budget-request/components/js/some-functions';

@Component({
    name: 'c-agr-spf-log-modal'
})
export default class CBudgetAgreementSpfLogModal extends Vue {
    private showModal = false;

    private progress = 0;

    private logLst: any[] = [];

    private showEvent(params: any) {
        this.showModal = true;
        this.loadData(params);
    }
    
    private async loadData(params: any) {
        this.logLst = [];
        /*params = { 
            abp: 110, 
            variant: 'fcf1463f-7146-42b8-9630-ca7081533572', 
            region: '450101', 
            curYear: 2024,
            dataType: '3',
            prg: 1, 
            ppr: 11, 
            spf: 135 
        }*/
        this.progress = 35;
        let response: any = null;
        try {
            response = await fetch(`/api-py/get-budget-agreement-spf-log/${encodeURI(JSON.stringify(params))}`);
            this.progress = 85;
            response = await response.json();
        } catch (error) {
            makeToast(this, 'danger', 'Ошибка get-budget-agreement-spf-log', (error as Error).toString());
        } finally {
            this.progress = 100;
        }
        await this.getLog(response);
    }

    private async getLog(response: any[]) {
        const userMap = new Map();
        for (const el of response) {
            const userObj: {name: string} = await this.getUserObj(el.userId, userMap);
            el.userName = userObj.name;
            el.dt = el.updateDate ? this.dateFormat(new Date(el.updateDate)) : null;
            if (el.status === 0) {
                el.statusName = 'Не соответствует';
            } else if (el.status === 1) {
                el.statusName = 'Соответствует';
            }
        }
        this.logLst = response;
    }

    private async getUserObj(userId: string, userMap: Map<string, any>) {
        let userObj = await userMap.get(userId);
        if (!userObj) {
            const name = await this.getUserName(userId);
            userObj = { name: name };
            userMap.set(userId, userObj);
        }
        return userObj;
    }

    private async getUserName(userId: string) {
        const response: any = await fetch(`/api-py/get-realm-user/${encodeURI(userId)}`);
            if (response.status === 200) {
                const result = await response.json();
                if (!result) { return ''; }
                return (result.firstName ? `${result.firstName} `: '') + (result.lastName ? result.lastName: '');
            } else {
                makeToast(this, 'danger', 'Ошибка /api-py/get-realm-user', userId);
                return '';
            }
    }

    private dateFormat(date: Date): string {
        return new Intl.DateTimeFormat(this.$i18n.locale, {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric'
        }).format(date);
    }

}
