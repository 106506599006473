












































import { Component, Vue, Prop } from 'vue-property-decorator';
import { makeToast } from '@/modules/budget-request/components/js/some-functions';

@Component({
    name: 'c-sign-log-modal'
})
export default class CBudgetUserSignLogModal extends Vue {
    private showModal = false;

    private progress = 0;

    private logLst: any[] = [];

    private showEvent(params: any) {
        this.showModal = true;
        this.loadData(params);
    }
    
    private async loadData(params: any) {
        this.logLst = [];
        this.progress = 35;
        try {
            let response: any = await fetch(`/api-py/get-budget_request_form_total_sign/${encodeURI(JSON.stringify(params))}`);
            this.progress = 85;
            response = await response.json();
            await this.getLog(response);
        } catch (error) {
            makeToast(this, 'danger', 'Ошибка get-budget_request_form_total_sign', (error as Error).toString());
        } finally {
            this.progress = 100;
        }
    }

    private async getLog(response: any[]) {
        const userMap = new Map();
        const signArr: any[] = []; // список подписанных
        const recalMap: Map<number, any> = new Map();

        for (const el of response) {
            let status = { status: 2, statusName: 'Подписано с ЭЦП' };
            let userObj: {name: string} = await this.getUserObj(el.user_id, userMap);
            const obj = {
                userId: el.user_id,
                dt: this.dateFormat(new Date(el.create_date)),
                dt_num: el.create_date,
                commentTxt: null,
                userName: userObj.name,
                status: status.status,
                statusName: status.statusName
            }
            signArr.push(obj);
            // ----
            if (el.deleted && !recalMap.get(el.del_date)) {
                if (el.comment_txt === null) {
                    status = { status: 0, statusName: 'Автоматический отзыв ЭЦП' };
                } else {
                    status = { status: 1, statusName: 'Пользовательский отзыв ЭЦП' };
                }
                userObj = await this.getUserObj(el.del_user_id, userMap);
                const objR = {
                    userId: el.del_user_id,
                    dt: this.dateFormat(new Date(el.del_date)),
                    dt_num: el.del_date,
                    commentTxt: el.comment_txt,
                    userName: userObj.name,
                    status: status.status,
                    statusName: status.statusName
                }
                recalMap.set(el.del_date, objR);
            }
        }

        const recalArr: any[] = [...recalMap.values()];
        const allArr = [...signArr, ...recalArr];
        allArr.sort((a: any, b: any) => (b.dt_num - a.dt_num > 0) ? 1 : -1);
        this.logLst = allArr;
    }

    private async getUserObj(userId: string, userMap: Map<string, any>) {
        let userObj = await userMap.get(userId);
        if (!userObj) {
            const name = await this.getUserName(userId);
            userObj = { name: name };
            userMap.set(userId, userObj);
        }
        return userObj;
    }

    private async getUserName(userId: string) {
        const response: any = await fetch(`/api-py/get-realm-user/${encodeURI(userId)}`);
            if (response.status === 200) {
                const result = await response.json();
                if (!result) { return ''; }
                return (result.firstName ? `${result.firstName} `: '') + (result.lastName ? result.lastName: '');
            } else {
                makeToast(this, 'danger', 'Ошибка /api-py/get-realm-user', userId);
                return '';
            }
    }

    private dateFormat(date: Date): string {
        return new Intl.DateTimeFormat(this.$i18n.locale, {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric'
        }).format(date);
    }

}
