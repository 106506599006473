














































































































import {Component, Vue, Prop} from 'vue-property-decorator';
import { setNameLang, makeToast } from '@/modules/budget-request/components/js/some-functions';
import { IBatchUpParams, startBatchUpload } from '@/modules/budget-request/components/js/budget-upload';
import store from '@/services/store';
import { Ax } from '@/utils';

@Component({
    components: {
    },
    name: 'c-budg-upload'
})
export default class CBubgUpload extends Vue {
    @Prop({
        required: true,
        default: 1
    })
    private status!: number;

    private progress = 0;
    private dictStatusMap: Map<number, any> = new Map();

    private dataLst: any[] = [];


    private get usrId(): string | null {
        if (!store.state.user.sub) { return null; }
        return store.state.user.sub;
    }


    private mounted() {
        this.loadAll();
        this.$watch('usrId', () => {
            if (!this.usrId) { return; }
            this.getDataLst();
        });
        this.$watch('status', () => { this.getDataLst(); });
    }

    private async getDataLst() {
        const dataLst = await this.loadData();
        this.setStatusName(dataLst);
    }

    private async loadAll() {
        const result = await Promise.all([
            this.loadData(),
            this.loadDictStatus()
        ]);
        this.setStatusName(result[0]);
    }

    private dataUnionStatus(el: any) {
        const obj = this.dictStatusMap.get(el.status);
        el.status = { code: el.status, name_ru: '', name_kk: '', name: '', progress: el.percent };
        if (obj) {
            el.status.name_ru = obj.name_ru;
            el.status.name_kk = obj.name_kk;
            el.status = setNameLang(this.$i18n.locale, el.status);
        }
        if (this.status === 1) {
            if (el.status.code === 3) {
                el.status.downloadPermision = (this.usrId===el.user_id);
            }
        }
    }

    private setStatusName(dataLst: any[]) {
        for (const el of dataLst) {
            this.dataUnionStatus(el);
        }
        this.dataLst = dataLst;
    }

    private async loadDictStatus() {
        let result: any[] = [];
        try {
            const response: any = await fetch('/api-py/get-budget-dict-upload-status-all');
            if (response.status === 200) {
                result = await response.json();
                for (const el of result) {
                    this.dictStatusMap.set(el.code, el);
                }
            } else {
                makeToast(this, 'danger', 'Ошибка загрузки справочника статусов', `${response.status} ${response.statusText}`);
            }
        } catch (error) {
            makeToast(this, 'danger', 'Ошибка загрузки справочника статусов', (error as Error).toString());
            result = [];
        }
    }

    private async loadData() {
        this.dataLst = [];
        let result: any[] = [];
        if (!this.usrId) { return []; }
        this.progress = 45;
        try {
            const response: any = await fetch(`/api/summary/report/list?uid=${this.usrId}&status=${this.status}`);
            if (response.status === 200) {
                result = await response.json();
                for (const el of result) {
                    if (el.dtCreate) { el.dtCreate = new Date(el.dtCreate); }
                    if (el.dtFinish) { el.dtFinish = new Date(el.dtFinish); }
                }
            } else {
                makeToast(this, 'danger', 'Ошибка /api/summary/report/', `${response.status} ${response.statusText}`);
                this.progress = 0;
                
            }
        } catch (error) {
            makeToast(this, 'danger', 'Ошибка /api/summary/report/', (error as Error).toString());
            this.progress = 0;
            result = [];
        } finally {
            this.progress = 100;
        }
        return result;
    }

    private async loadClk(params: any) {
        if (!params.curVariant || !params.curAbp || !params.regionProp) { 
            makeToast(this, 'danger', 'Загрузка', 'Выбраны не все параметры!');
            return; 
        }
        const p: IBatchUpParams = { cur_year: params.curYear, region: params.regionProp.code, variant: params.curVariant.variant_uuid, abp: params.curAbp.abp, lang: this.$i18n.locale, gu: (params.curGu && params.curGu.code) ? params.curGu.code: null, prg: params.prg };
        await startBatchUpload(p, this);
        this.getDataLst();
    }

    private async updateStatus(indx: number) {
        const id = this.dataLst[indx].id;
        let result = null;
        try {
            const response: any = await fetch(`/api/summary/report/status/${id}`);
            if (response.status === 200) {
                result = await response.json();
                this.dataLst[indx].uploadName = result.uploadName;
                const tmp = Object.assign({}, this.dataLst[indx]);

                tmp.status = result.status;
                tmp.percent = result.percent;
                
                this.dataUnionStatus(tmp);
                this.$set(this.dataLst[indx], 'status', tmp.status);
                makeToast(this, 'success', 'Запись обновлена', `id: ${id}`);
            } else {
                makeToast(this, 'danger', 'Ошибка обновления', `${response.status} ${response.statusText}`);
            }
        } catch (error) {
            makeToast(this, 'danger', 'Ошибка обновления', (error as Error).toString());
            result = [];
        }
    }

    private uploadFile(indx: number) {
        const id = this.dataLst[indx].id;
        let uplaodName = 'Пакетная выгрузка';
        if (this.dataLst[indx].uploadName) { uplaodName = this.dataLst[indx].uploadName; }
        const urls = `/api/summary/report/getfile?id=${id}`;
        Ax<Blob>(
            {
                url: urls,
                method: 'GET',
                responseType: 'blob'
            },
            (data) => {
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${uplaodName}.zip`);
                document.body.appendChild(link);
                link.click();
                window.URL.revokeObjectURL(url);
            },
            (error) => {
                makeToast(this, 'danger', 'Ошибка Загрузки', error.toString());
            },
            () => {
                this.getDataLst();
            }
        );
    }

    private dateTimeFormat () {
      return new Intl.DateTimeFormat('ru', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
      })
    }
}
