































import { Component, Vue, Prop } from 'vue-property-decorator';
import {getBipTitle, getSmsTitle} from "@/modules/budget/bip/bip-types";

export interface iAgrBtns {
    back: any[],
    forward: any[]
}

@Component({
    name: 'c-budg-agr-modal'
})
export default class CBudgetAgreementModal extends Vue {
    @Prop({
        required: true,
        default: { back: [], forward: [] }
    })
    private agrBtnLst!: iAgrBtns;

    created() {
        this.$watch('agrBtnLst', () => {
            try {
                for (const btn of this.agrBtnLst.back) {
                    if (!btn.name) {
                        btn.name = btn[`name_${this.$i18n.locale}`];
                    }
                }
                for (const btn of this.agrBtnLst.forward) {
                    if (!btn.name) {
                        btn.name = btn[`name_${this.$i18n.locale}`];
                    }
                }
            } catch {}
        }, {deep: true});
    }

    private showModal = false;

    private commentTxt = '';

    private showEvent() {
        this.showModal = true;
    }

    private clkBtn(code: number) {
        const saveObj = { status: code, commentTxt: this.commentTxt };
        this.$emit('agrClk', saveObj);
        this.showModal = false;
        this.commentTxt = '';
    }

    getBipTitle(field: string) {
        return getBipTitle(this, field);
    }

    getSmsTitle(field: string) {
        return getSmsTitle(this, field);
    }
}
